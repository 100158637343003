import React from 'react';
import Layout from '../components/layout';

const Contact = () => (
  <Layout>
    <section className="breadcrumb-area">
      <div className="container text-center">
        <h1>Recharge Plans</h1>
      </div>
    </section>

    <section className="breadcrumb-botton-area">
      <div className="container">
        <div className="left pull-left">
          <ul>
            <li><a href="index.html">Home</a></li>
            <li><i className="fa fa-caret-right" aria-hidden="true" /></li>
            <li>Recharge Plans</li>
          </ul>
        </div>
        <div className="right pull-right">
          {/* <a href="#">
            <i className="fa fa-share-alt" aria-hidden="true" />
Share
          </a> */}
        </div>
      </div>
    </section>

    <section className="pricing-plan-one-area">
      <div className="container">
        <div className="sec-title-two text-center">
          <h1>Monthly Recharge Plans</h1>
          <span className="border" />
        </div>
        <div className="row">
          {/* Start single pricing box */}
          <div className="col-md-6">
            <div className="single-pricing-box">
              <div className="pricing-plan">
                <div className="table-top text-center">
                  <h3>
                    Chatr Wireless
                  </h3>
                </div>
                <table>
                  <thead className="table-heading">
                    <tr>
                      <th colSpan="2">TALK, TEXT & DATA PLANS</th>
                      <th>
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    <tr>
                      <td>
                        <h5>8 GB + Bonus 500 MB at 3G speed</h5>
                      </td>
                      <td>
                        <h6>Unlimited Canada-wide Talk & International Text</h6>
                      </td>
                      <td>
                        <h6>$50</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>6 GB + Bonus 500 MB at 3G speed</h5>
                      </td>
                      <td>
                        <h6>Unlimited Canada-wide Talk & International Text</h6>
                      </td>
                      <td>
                        <h6>$45</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>4 GB + Bonus 500 MB at 3G speed</h5>
                      </td>
                      <td>
                        <h6>Unlimited Canada-wide Talk & International Text</h6>
                      </td>
                      <td>
                        <h6>$40</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>Optional Data</h5>
                      </td>
                      <td>
                        <h6>Unlimited Canada/US Talk & International Text*</h6>
                      </td>
                      <td>
                        <h6>$45</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>Optional Data</h5>
                      </td>
                      <td>
                        <h6>Unlimited Canada-wide Talk & International Text*</h6>
                      </td>
                      <td>
                        <h6>$35</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>Optional Data</h5>
                      </td>
                      <td>
                        <h6>Unlimited Province-wide Talk & 100 International Texts*</h6>
                      </td>
                      <td>
                        <h6>$25</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>Optional Data</h5>
                      </td>
                      <td>
                        <h6>50 Canada-wide Talk & 50 International Texts*</h6>
                      </td>
                      <td>
                        <h6>$10</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>Local Talk Plan</h5>
                      </td>
                      <td>
                        <h6>Unlimited Local Talk</h6>
                      </td>
                      <td>
                        <h6>$20</h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* End single pricing box */}
          {/* Start single pricing box */}
          <div className="col-md-6">
            <div className="single-pricing-box">
              <div className="pricing-plan">
                <div className="table-top text-center">
                  <h3>Public Mobile</h3>
                </div>
                <table>
                  <thead className="table-heading">
                    <tr>
                      <th colSpan="2">Ready-Made Plans</th>
                      <th>
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-body">
                    <tr>
                      <td>
                        <h5>50 TEXTS Outgoing International Text</h5>
                      </td>
                      <td>
                        <h6>50 MINUTES Canada-wide Talk</h6>
                      </td>
                      <td>
                        <h6>$10</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>Data Only</h5>
                      </td>
                      <td>
                        <h6>250 MB at 3G Speed</h6>
                      </td>
                      <td>
                        <h6>$20</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>No Data</h5>
                      </td>
                      <td>
                        <h6>Unlimited Talk</h6>
                      </td>
                      <td>
                        <h6>$20</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>Unlimited Talk</h5>
                      </td>
                      <td>
                        <h6>Unlimited International Text</h6>
                      </td>
                      <td>
                        <h6>$25</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>500 MB at 3G Speed</h5>
                      </td>
                      <td>
                        <h6>Unlimited Canada-wide Talk & International Text</h6>
                      </td>
                      <td>
                        <h6>$30</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>Data Only</h5>
                      </td>
                      <td>
                        <h6>1 GB of 4G LTE Data</h6>
                      </td>
                      <td>
                        <h6>$30</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>1 GB Data at 3G Speed</h5>
                      </td>
                      <td>
                        <h6>Unlimited Canada-wide Talk & International Text</h6>
                      </td>
                      <td>
                        <h6>$35</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>4.5 GB Data at 3G Speed</h5>
                      </td>
                      <td>
                        <h6>Unlimited Canada-wide Talk & International Text</h6>
                      </td>
                      <td>
                        <h6>$40</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>6 GB Data at 3G Speed</h5>
                      </td>
                      <td>
                        <h6>Unlimited Canada-wide Talk & International Text</h6>
                      </td>
                      <td>
                        <h6>$45</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h5>2 GB of 4G LTE Data</h5>
                      </td>
                      <td>
                        <h6>Unlimited Talk & International Text</h6>
                      </td>
                      <td>
                        <h6>$47</h6>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* End single pricing box */}
        </div>
      </div>
    </section>

  </Layout>
);
export default Contact;
